<template>
  <b-sidebar
    id="add-new-locations-sidebar"
    :visible="isAddNewLocationsSidebarActive"
    :organisation-options="organisationOptions"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-locations-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Locatie aanmaken
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name-->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Naam"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="locationsData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="organisations"
              rules="required"
          >
            <b-form-group
                label="Organisatie"
                label-for="organisations"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="locationsData.companyID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="organisationOptions"
                  value="id"
                  label="name"
                  :reduce="organisation => organisation.id"
                  :clearable="false"
                  input-id="organisations"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Aanmaken
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuleren
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewLocationsSidebarActive',
    event: 'update:is-add-new-locations-sidebar-active',
  },
  props: {
    isAddNewLocationsSidebarActive: {
      type: Boolean,
      required: true,
    },
    organisationOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankLocationsData = {
      name: '',
      companyID: null,
    }

    const locationsData = ref(JSON.parse(JSON.stringify(blankLocationsData)))
    const resetLocationsData = () => {
      locationsData.value = JSON.parse(JSON.stringify(blankLocationsData))
    }

    const onSubmit = () => {
      store.dispatch('app-locations-list/addLocation', locationsData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-locations-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetLocationsData)

    return {
      locationsData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-locations-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
